import axios from '@/utils/request'

/**
 * 获取相关材质
 */
  export function getAllData (parameter = {}) {
    return axios({
      url: '/api/ypl/GetAllData',
      method: 'post',
      data: parameter
    })
  }
  export function pushOrder (parameter = {}) {
    return axios({
      url: '/api/ypl/PushOrder',
      method: 'post',
      data: parameter
    })
  }