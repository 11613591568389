<template>
  <div>
    <van-nav-bar
      left-text="着装顾问"
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="goods">
      <!-- <van-cell-group>
        <van-cell title="量体师" :value="master" />
        <van-cell title="客户" :value="user" />
      </van-cell-group>
      <van-divider>定制信息</van-divider> -->

      <van-form @submit="onSubmit">
        <van-field
          v-model="form.meeter"
          name="量体师"
          label="量体师"
          placeholder="请填写量体师名称"
          input-align="right"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="form.meeterPhone"
          name="量体师电话"
          label="量体师电话"
          placeholder="请填写量体师电话"
          input-align="right"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="form.linkman"
          name="客户"
          label="客户"
          placeholder="请填写客户名称"
          input-align="right"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="form.linkphone"
          name="客户电话"
          label="客户电话"
          placeholder="请填写客户电话"
          input-align="right"
          :rules="[{ required: true }]"
        />
        <van-divider>定制信息</van-divider>
        <van-field
          v-model="form.orderId"
          name="订单号"
          label="订单号"
          placeholder="请填写订单号"
          input-align="right"
          :rules="[{ required: true }]"
        />

        <van-field
          name="面料编号"
          label="面料编号"
          readonly
          clickable
          @click="typeSel('fabric')"
          v-model="form.fabric"
          :rules="[{ required: true }]"
          input-align="right"
        >
          <template #input>
            <div>
              <div :style="{color:form.fabric?'#323233':'#b2b2b2'}">{{form.fabric || '点击选择面料编号'}}</div>
              <div>
                <img v-if="fabricImage" style="width:50px;height:50px" :src="fabricImage" mode="contain"/>
              </div>
            </div>
          </template>
        </van-field>
        

        <van-field
          v-model="form.moduleNo"
          name="鞋型编号"
          label="鞋型编号"
          placeholder="点击选择鞋型编号"
          readonly
          clickable
          @click="typeSel('moduleNo')"
          :rules="[{ required: true }]"
          input-align="right"
        />

        <!-- <van-field 
          name="switch" 
          label-width="7em" 
          label="左右脚不同跟高" 
          input-align="right"
        >
          <template #input>
            <van-switch v-model="form.heelSwitch" size="20" />
          </template>
        </van-field> -->

        <van-field
          name="鞋跟类型"
          label="鞋跟类型"
          v-model="form.heel"
          readonly
          clickable
          @click="typeSel('heel')"
          :rules="[{ required: true }]"
          input-align="right"
        >
          <template #input>
            <div>
              <div :style="{color:form.heel?'#323233':'#b2b2b2'}">{{form.heel || '点击选择鞋跟类型'}}</div>
              <div>
                <img v-if="heelImage" style="width:50px;height:50px" :src="heelImage" mode="contain"/>
              </div>
            </div>
          </template>
        </van-field>

        <!-- <van-field 
          name="switch" 
          label-width="7em" 
          label="左右脚不同鞋码"
          input-align="right"
        >
          <template #input>
            <van-switch v-model="form.sizeSwitch" size="20" />
          </template>
        </van-field> -->

        <!-- <van-field
          v-model="form.leftSize"
          name="左脚鞋码"
          label="左脚鞋码"
          readonly
          clickable
          @click="typeSel('leftSize')"
          placeholder="请选择左脚鞋码"
          input-align="right"
          :rules="[{ required: true, message: '请填写左脚鞋码' }]"
        /> -->

        <van-field
          v-model="form.size"
          name="鞋码"
          label="鞋码"
          readonly
          clickable
          @click="typeSel('size')"
          placeholder="请选择鞋码"
          input-align="right"
          :rules="[{ required: true }]"
        />

        <van-field
          v-model="form.sole"
          name="鞋底材质"
          label="鞋底材质"
          placeholder="点击选择鞋底材质"
          readonly
          clickable
          @click="typeSel('sole')"
          :rules="[{ required: true }]"
          input-align="right"
        />

        <van-field
          v-model="form.head"
          name="头型"
          label="头型"
          placeholder="点击选择头型"
          readonly
          clickable
          @click="typeSel('head')"
          :rules="[{ required: true }]"
          input-align="right"
        />

        <van-field
          v-model="form.mouth"
          name="口型"
          label="口型"
          placeholder="点击选择口型"
          readonly
          clickable
          @click="typeSel('mouth')"
          :rules="[{ required: true }]"
          input-align="right"
        />

        <van-field
          v-model="form.lettering"
          name="刻字"
          label="刻字"
          placeholder="请输入刻字"
          :rules="[{ required: true }]"
          input-align="right"
        />

        <van-field
          name="饰扣"
          label="饰扣"
          placeholder="点击选择饰扣"
          v-model="form.stud"
          readonly
          clickable
          @click="typeSel('stud')"
          :rules="[{ required: true }]"
          input-align="right"
        >
          <template #input>
            <div>
              <div :style="{color:form.stud?'#323233':'#b2b2b2'}">{{form.stud || '点击选择饰扣'}}</div>
              <div>
                <img v-if="studImage" style="width:50px;height:50px" :src="studImage" mode="contain"/>
              </div>
            </div>
          </template>
        </van-field>

        <van-field
          v-model="form.extension1"
          name="备注"
          label="备注"
          placeholder="请输入备注"
          input-align="right"
        />

        <div style="margin: 16px;">
          <van-button :loading="disable" :disable="disable" loading-text="加载中..." round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>

      <!-- 总 -->
      <van-popup v-model="typeShow" position="bottom" round :style="{ maxHeight: '500px' }">
        <div class="goods-flex comfirm-row">
          <div><span class="goods-flex-cancelbtn" @click="typeShow = false">取消</span></div>
          <div><span class="blue-text" @click="comfirmType">确认</span></div>
        </div>
        <div>
          <van-search v-if="choseType == 'fabric' || choseType == 'heel' || choseType == 'stud'" v-model="searchKey" @search="onSearch" show-action placeholder="搜索颜色、编号">
            <template #action>
              <div @click="onSearch">搜索</div>
            </template>
          </van-search>
        </div>
        <div class="type-box">
          <van-radio-group v-model="typeRadio">
            <div v-for="(item, index) in typeList" :key="index" class="type-row">
              <van-radio :name="index">
                <div class="goods-flex">
                  <img v-if="item.image" class="type-img" v-lazy="item.image" :src="item.image" mode="contain"/>
                  <span class="type-name">
                    {{item.name}}
                    <div v-if="item.code">{{item.code}}</div>
                  </span>
                </div>
              </van-radio>
            </div>
          </van-radio-group>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { NavBar,Toast,Cell,CellGroup,Form,Field,Button,Divider,popup,Switch,RadioGroup,Radio,Lazyload,Search } from 'vant';
import { getAllData, pushOrder } from '@/api/shose'
import Vue from 'vue'
Vue.use(Lazyload, {
  lazyComponent: true,
});
export default {
  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [popup.name]: popup,
    [Switch.name]: Switch,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Lazyload.name]: Lazyload,
    [Search.name]: Search,
  },

  data() {
    return {
      // 搜索关键字
      searchKey: '',

      disable: false,

      typeRadio: '',
      // 面料
      fabricList: [],
      fabricImage: '',
      // 鞋型编号
      moduleNoList: [
        { name: 'A套餐' },
        { name: 'B套餐' },
        { name: 'C套餐' },
        { name: 'D套餐' },
      ],
      // 鞋跟类型
      heelList: [],
      heelImage:'',

      // 鞋码
      sizeList: [],
      // 鞋底材质
      soleList: [
        { name: '杏色大底' },
        { name: '黑色大底' },
        { name: '红色大底' },
        { name: '红色祥云底' },
      ],
      // 头型
      headList: [
        { name: '尖头' },
        { name: '超尖头' },
        { name: '圆头' },
        { name: '小圆头' },
        { name: '方头' },
        { name: '小方头' },
      ],
      // 口型
      mouthList: [
        { name: '超浅口' },
        { name: '浅口' },
        { name: '爱心口' },
      ],
      // 饰扣
      studList: [],
      studImage: '',

      form: {
        meeter: '',
        meeterPhone: '',
        linkman: '',
        linkphone: '',
        orderId: '',
        fabric: '',
        fabricId: '',
        heelSwitch: false,
        sizeSwitch: false,
        moduleNo: '',
        heel: '',
        heelId: '',
        size: '',
        sole: '',
        head: '',
        mouth: '',
        stud: '',
        lettering: '',
        extension1: ''
      },
      choseType: '',
      typeList: [],
      typeShow: false,
      goods: {}
    };
  },

  // computed: {
  //   master() {
  //     return this.masterName + ' - ' + this.masterPhone
  //   },
  //   user() {
  //     return this.userName + ' - ' + this.userPhone
  //   }
  // },

  mounted() {
    // let phone = this.$route.query.userPhone || ''
    // let name = this.$route.query.userName || ''
    // let masterPhone = this.$route.query.masterPhone || ''
    // let masterName = this.$route.query.masterName || ''

    // this.masterName = masterName
    // this.masterPhone = masterPhone
    // this.userName = name
    // this.userPhone = phone

    // 获取码数
    let sizeList = []
    for(let i = 32; i<42; i+=0.5) {
      sizeList.push({
        name: i
      })
    }
    this.sizeList = sizeList
    
    this.getChoseData()
  },

  methods: {
    getChoseData(type) {
      // 获取参数
      getAllData({
        searchText: this.searchKey
      }).then(res => {
        let fabricList = []
        let heelList = []
        let studList = []
        if (res.code == 0 && res.data.length > 0) {
          res.data.forEach(item => {
            if(item.modelStoreType == 'ModelFabric') {
              fabricList.push(item)
            }
            if(item.modelStoreType == 'ModelHeel') {
              heelList.push(item)
            }
            if(item.modelStoreType == 'ModelStud') {
              studList.push(item)
            }
          })
        }

        if (type == 'search') {
          switch (this.choseType) {
            case 'fabric' :
              this.fabricList = fabricList;
              break;
            case 'heel':
              this.heelList = heelList
              break
            case 'stud':
              this.studList = studList   
              break
          }
          this.typeSel(this.choseType)
        } else {
          this.fabricList = fabricList
          this.heelList = heelList
          this.studList = studList
        }
      })
    },
    onSearch() {
      this.getChoseData('search')
    },
    onClickLeft() {
      Toast('退回')
    },
    typeSel(type) {
      this.choseType = type
      let list = []
      switch(type) {
        case 'fabric': 
          list = this.fabricList
          break
        case 'moduleNo': 
          list = this.moduleNoList
          break
        case 'heel': 
          list = this.heelList
          break
        case 'sole': 
          list = this.soleList
          break
        case 'size': 
          list = this.sizeList
          break
        case 'head': 
          list = this.headList
          break
        case 'mouth': 
          list = this.mouthList
          break
        case 'stud': 
          list = this.studList
          break
        default:
          list = []
      }
      this.typeRadio = ''
      this.typeList = list
      this.typeShow = true
    },
    comfirmType() {
      let index = this.typeRadio
      let choseType = this.choseType
      this.form[this.choseType] = this.typeList[index].name

      let typeId = this.typeList[index].id
      let img = this.typeList[index].image
      // 面料
      if (choseType == 'fabric') {
        this.form.fabricId = typeId
        this.fabricImage = img
      }
      // 鞋跟
      if (choseType == 'heel') {
        this.form.heelId = typeId
        this.heelImage = img
      }
      // 饰扣
      if (choseType == 'stud') {
        this.form.studId = typeId
        this.studImage = img
      }
      this.typeShow = false
    },
    onSubmit() {
      this.disable = true
      let params = {
        heelName: this.form.heel,
        leftSize: this.form.size,
        rightSize: this.form.size,
        groundName: this.form.sole,
        headName: this.form.head,
        mouthName: this.form.mouth,
        font: this.form.lettering,
        decorate: this.form.stud,
      }
      let data = Object.assign(params, this.form)
      pushOrder(data).then(res => {
        this.disable = false
        if (res.code !== 0) {
          Toast(res.msg)
          return
        }
        Toast('提交成功')
        this.form.meeter = ''
        this.form.meeterPhone = ''
        this.form.linkman = ''
        this.form.linkphone = ''
        this.form.orderId = ''
      })
    }
  }
};
</script>

<style lang="less">
.goods {
  &-flex{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
  }
}
.type {
  &-img{
    width: 60px;
    height: 60px
  }
  &-name{
    margin-left: 15px;
  }
}
.type {
  &-box{
    max-height: 400px;
    overflow: hidden;
    overflow-y: scroll;
  }
  &-row {
  margin: 10px 10px;
  border-bottom: 1px solid #ddd;
}
}
.comfirm-row {
  height: 30px;
}
.blue-text {
  color: #1989fa;
}
</style>
